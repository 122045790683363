import React from "react"
import { Link } from "gatsby"
import { withMentorFeedbackComplete } from "./withMentorFeedbackComplete"
import { Container, Title, Description, Emoji, Button } from "./MentorFeedbackComplete.styled"

export const MentorFeedbackComplete = withMentorFeedbackComplete(
  ({}: Props): JSX.Element => {
  return (
    <Container spacin width={`md`}>
      <Title>Thank you!</Title>
      <Description>Your feedback is super important to us in order to continue to improve the She Mentors experience.</Description>
      <Emoji>🙏</Emoji>
      <Button as={Link} to={`/account/reviews`} size={`large`} theme={`primary`} colour={`dark`}>
        Back to Reviews
      </Button>
    </Container>
  )
})

export interface Props {}