import tw, { styled } from "twin.macro"
import { StyledButton } from "../../styled/Button"
import { StyledContainer } from "../../styled/Container"
import { H2, P } from "../../styled/Text"

export const Container = styled(StyledContainer)`
  ${tw`h-full bg-grey-lightest md:bg-transparent md:h-auto flex flex-col items-center text-center`}
`
export const Button = styled(StyledButton)`
  ${tw`block w-full md:w-auto mt-8 mb-8`}
`
export const Title = styled(H2)`
  ${tw`text-grey-dark mt-8 w-full mb-2`}
`
export const Description = styled(P)`
  ${tw`text-grey-mid max-w-sm mx-auto mb-12`}
`
export const Emoji = tw.p`text-12xl text-center w-full leading-none`