import React, { useMemo } from "react"

export const withMentorFeedbackComplete = Component =>
  React.memo(({ name="withMentorFeedbackComplete" }) => {
    Component.displayName = name

    return useMemo(
      () => (
        <Component />
    ), [])
  })